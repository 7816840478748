import {
  Box,
  Flex,
  HStack,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
} from '@chakra-ui/react';
import { FeatureFlag, PERMISSIONS, Report, ReportStatus } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Attachments } from '../../../features/attachments/components/Attachments';
import { AttachmentsBadge } from '../../../features/attachments/components/AttachmentsBadge';
import { Distributions } from '../../../features/distributions/components/Distributions';
import { DistributionsBadge } from '../../../features/distributions/components/DistributionsBadge';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { AutoSaveStatusIndicator } from '../../forms/hookform/AutoSaveStatusIndicator';
import { Content } from '../../generic/Content';
import { TabsRouter } from '../../generic/TabsRouter';
import { ImageViewer } from '../../viewer/ImageViewer';
import { ImagesBadge } from './ImagesBadge';
import { MeasurementsBadge } from './MeasurementsBadge';
import { RelatedReports } from './RelatedReports';
import { RelatedReportsBadge } from './RelatedReportsBadge';
import { ReportEditor } from './ReportEditor';
import { ReportExtraContent } from './ReportExtraContent';
import { ReportHeader } from './ReportHeader';
import { ReportVersionStatus } from './ReportVersionStatus';
import { VariablesEditor } from './VariablesEditor';
import { WorksheetEditor } from './WorksheetEditor';

interface Props {
  report: Report;
}

export const ReportContent = (props: Props) => {
  const { report } = props;

  const { organization } = useAppContext();
  const isSupport = usePermission(PERMISSIONS.organizationSupport).value;
  const showMeasurementsSidebarEnabled =
    organization.hasFeature(FeatureFlag.InAppMeasurements) || isSupport;

  const reportVersionStatus = P.run(() => {
    if (P.isNil(report)) {
      return null;
    }

    if (report.reportTemplate.isLatest || report.status === ReportStatus.Finalized) {
      return null;
    }

    return (
      <Box p={4}>
        <ReportVersionStatus reportId={report.id} />
      </Box>
    );
  });

  return (
    <Content
      rightExtraContent={<ReportExtraContent reportId={report.id} />}
      headerContent={<ReportHeader reportId={report.id} />}
    >
      <Flex h="full" w="full">
        <ReportEditor reportId={report.id}>
          <Flex h="full" w="full">
            <Box overflowX="auto" overflowY="auto" flexGrow={1}>
              <TabsRouter
                routes={[
                  'worksheet',
                  'images',
                  'measurements',
                  'related-reports',
                  'attachments',
                  'distributions',
                ]}
                colorScheme="purple"
                w="full"
                h="full"
                display="flex"
                flexDir="column"
                isLazy
                lazyBehavior="keepMounted"
              >
                <TabList>
                  <Tab key="worksheet" data-pw="reportWorksheetTab" fontSize="sm">
                    Worksheet
                  </Tab>
                  <Tab key="images" data-pw="reportImagesTab">
                    <HStack>
                      <Text fontSize="sm">Images</Text>
                      <ImagesBadge reportId={report.id} />
                    </HStack>
                  </Tab>
                  <Tab key="measurements" data-pw="reportMeasurementsTab">
                    <HStack>
                      <Text fontSize="sm">Measurements</Text>
                      <MeasurementsBadge reportId={report.id} />
                    </HStack>
                  </Tab>
                  <Tab key="related-reports" data-pw="reportRelatedReportsTab">
                    <HStack>
                      <Text fontSize="sm">Related reports</Text>
                      <RelatedReportsBadge reportId={report.id} />
                    </HStack>
                  </Tab>
                  <Tab key="attachments" data-pw="reportAttachmentsTab">
                    <HStack>
                      <Text fontSize="sm">Attachments</Text>
                      <AttachmentsBadge reportId={report.id} />
                    </HStack>
                  </Tab>
                  <Tab key="distributions" data-pw="distributionsTab">
                    <HStack>
                      <Text fontSize="sm">Distributions</Text>
                      <DistributionsBadge reportId={report.id} />
                    </HStack>
                  </Tab>
                  <Spacer />
                  <HStack px={4}>
                    <AutoSaveStatusIndicator />
                  </HStack>
                </TabList>
                {reportVersionStatus}

                <TabPanels h="full" overflowY="auto">
                  <TabPanel key="worksheet-tabpanel" h="full">
                    <WorksheetEditor shouldWrap />
                  </TabPanel>
                  <TabPanel key="images-tabpanel" h="full">
                    <ImageViewer
                      reportId={report.id}
                      allowShowReport
                      allowShowMeasurements={showMeasurementsSidebarEnabled}
                    />
                  </TabPanel>
                  <TabPanel key="measurements-tabpanel" h="full">
                    <VariablesEditor reportId={report.id} />
                  </TabPanel>
                  <TabPanel key="related-studies-tabpanel" h="full">
                    <RelatedReports reportId={report.id} />
                  </TabPanel>
                  <TabPanel key="attachments-tabpanel" h="full">
                    <Attachments reportId={report.id} />
                  </TabPanel>
                  <TabPanel key="distributions-tabpanel" h="full">
                    <Distributions reportId={report.id} />
                  </TabPanel>
                </TabPanels>
              </TabsRouter>
            </Box>
          </Flex>
        </ReportEditor>
      </Flex>
    </Content>
  );
};
