import { Query } from '@cubejs-client/core';
import { DateTime, P } from '@piccolohealth/util';
import { useAppContext } from '../../../hooks/useAppContext';
import { CubeQueryFilter } from './useCubeQueryFilter';

const getCubeInfo = (query: Query, startDate: DateTime, endDate: DateTime) => {
  const timeDimensionObj = (query.timeDimensions || [])[0] || {};
  const cube = query.measures?.[0]?.split('.')?.[0] ?? 'Reports';
  const timeDimension = timeDimensionObj.dimension || `${cube}.createdAt`;
  const diff = endDate.diff(startDate, 'days');
  const proposedGranularity = P.run(() => {
    if (diff.days > 180) {
      return 'month';
    }

    if (diff.days > 60) {
      return 'week';
    }

    return 'day';
  });

  const granularity = timeDimensionObj.granularity ? proposedGranularity : undefined;

  return {
    timeDimension,
    granularity,
  };
};

export const useCubeQuery = (filter: CubeQueryFilter) => {
  const { organization } = useAppContext();

  const withTimeRange = <A extends Query>(query: A): A => {
    const { timeDimension, granularity } = getCubeInfo(query, filter.startDate, filter.endDate);

    return {
      ...query,
      timezone: organization.timezone,
      timeDimensions: [
        {
          dimension: timeDimension,
          dateRange: [filter.startDate.toString(), filter.endDate.toString()],
          granularity,
        },
      ],
    };
  };

  return {
    withTimeRange,
  };
};
