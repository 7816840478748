import { CacheLocation } from '@auth0/auth0-react';
import { ConfigResolver } from '@piccolohealth/config';
import { AsyncReturnType } from '@piccolohealth/echo-common';

export type Config = AsyncReturnType<typeof getConfig>;

export const getConfig = () => {
  //@ts-ignore
  const viteEnv = import.meta.env ?? {};
  //@ts-ignore
  const windowEnv = window.env ?? {};

  const env = { ...viteEnv, ...windowEnv };

  return ConfigResolver.build(env, (env) => {
    return {
      env: env.get('VITE_PICCOLO_ENV').required().asString(),
      lagRadarEnabled: env.get('VITE_PICCOLO_LAG_RADAR_ENABLED').asBool(),
      errorStackEnabled: env.get('VITE_PICCOLO_ERROR_STACK_ENABLED').asBool(),
      organizationMenuSuffix: env.get('VITE_PICCOLO_ORGANIZATION_MENU_SUFFIX').asString(),
      dicom: {
        ip: env.get('VITE_PICCOLO_DICOM_IP').required().asString(),
        hostname: env.get('VITE_PICCOLO_DICOM_HOSTNAME').required().asString(),
        port: env.get('VITE_PICCOLO_DICOM_PORT').required().asPortNumber(),
      },
      buildInfo: {
        commit: env.get('VITE_PICCOLO_GIT_COMMIT_HASH').asString(),
        branch: env.get('VITE_PICCOLO_GIT_BRANCH_NAME').asString(),
      },
      auth: {
        domain: env.get('VITE_PICCOLO_AUTH0_DOMAIN').required().asString(),
        audience: env.get('VITE_PICCOLO_AUTH0_AUDIENCE').required().asString(),
        clientId: env.get('VITE_PICCOLO_AUTH0_FRONTEND_CLIENT_ID').required().asString(),
        redirectUri: env.get('VITE_PICCOLO_AUTH0_FRONTEND_REDIRECT_URI').required().asString(),
        logoutRedirectUri:
          env.get('VITE_PICCOLO_AUTH0_FRONTEND_REDIRECT_URI').required().asString() + '/logout',
        responseType: env.get('VITE_PICCOLO_AUTH0_FRONTEND_RESPONSE_TYPE').required().asString(),
        cacheLocation: env
          .get('VITE_PICCOLO_AUTH0_FRONTEND_CACHE_LOCATION')
          .required()
          .asString() as CacheLocation,
      },
      api: {
        url: env.get('VITE_PICCOLO_URL').required().asString(),
        cubeJs: env.get('VITE_PICCOLO_URL').required().asString() + '/cubejs/cubejs-api/v1',
      },
      sentry: {
        environment: env.get('VITE_PICCOLO_ENV').required().asString(),
        dsn: env.get('VITE_PICCOLO_SENTRY_FRONTEND_DSN').required().asString(),
        enabled: env.get('VITE_PICCOLO_SENTRY_FRONTEND_ENABLED').required().asBool(),
        release: env.get('VITE_PICCOLO_GIT_COMMIT_HASH').asString(),
        normalizeDepth: 10,
      },
      intercom: {
        enabled: env.get('VITE_PICCOLO_INTERCOM_ENABLED').required().asBool(),
        appId: env.get('VITE_PICCOLO_INTERCOM_APP_ID').required().asString(),
        helpCenterUrl: env.get('VITE_PICCOLO_INTERCOM_HELP_CENTER_URL').required().asString(),
      },
      settings: {
        idleTimeout: env.get('VITE_PICCOLO_IDLE_TIMEOUT').required().asIntPositive(), //3600000, // 1hr,
        idleWarningTimeout: env.get('VITE_PICCOLO_IDLE_WARNING_TIMEOUT').required().asIntPositive(), //60000, // 1m
      },
    };
  });
};
