import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateIntegrations } from '../mutations/updateIntegrations.mutation';
import {
  useFaxIntegrationSettingsQuery,
  useHealthLinkIntegrationSettingsQuery,
  useMedicalObjectsIntegrationSettingsQuery,
} from './useIntegrationsQuery';
import { useOrganizationQuery } from './useOrganizationQuery';

export const useUpdateIntegrationsMutation = (
  options?: MutationOptions<
    Types.UpdateIntegrationsMutation,
    Types.UpdateIntegrationsMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateIntegrations), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useMedicalObjectsIntegrationSettingsQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
      queryClient.invalidateQueries(
        useHealthLinkIntegrationSettingsQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
      queryClient.invalidateQueries(
        useFaxIntegrationSettingsQuery.getKey({
          organizationId: variables.organizationId,
        }),
      );
      queryClient.invalidateQueries(
        useOrganizationQuery.getKey({ organizationId: variables.organizationId }),
      );
    },
  });
};
