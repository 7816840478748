import { useDisclosure } from '@chakra-ui/react';
import { Instance } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useReport } from '../../../../../context/ReportContext';
import { Layout } from '../../../controls/Layout';
import { HTMLPlayerElement } from '../../../hooks/usePlayerRefs';
import { MeasurementTool, MeasurementType, UltrasoundRegion } from '../../utils';
import { useAreaLengthVolumeTool } from './useAreaLengthVolumeTool';
import { useAreaTool } from './useAreaTool';
import { useLengthTool } from './useLengthTool';

export type MeasurementControlStep = 'typeSelection' | MeasurementType;

export interface Dimensions {
  scale: { x: number; y: number };
  imageWidth: number;
  imageHeight: number;
  containerWidth: number;
  containerHeight: number;
}

const getContainedSize = (element: HTMLPlayerElement | null): [number, number] => {
  if (!element) {
    return [0, 0];
  }

  const [naturalWidth, naturalHeight, elementWidth, elementHeight] = P.run(() => {
    if (element instanceof HTMLImageElement) {
      return [element.naturalWidth, element.naturalHeight, element.width, element.height];
    } else {
      return [element.videoWidth, element.videoHeight, element.clientWidth, element.clientHeight];
    }
  });

  const ratio = naturalWidth / naturalHeight;
  let width = elementHeight * ratio;
  let height = elementHeight;

  if (width > elementWidth) {
    width = elementWidth;
    height = elementWidth / ratio;
  }
  return [width, height];
};

interface UseMeasurementControlOptions {
  instance: Instance | null;
  playerRef: React.RefObject<HTMLPlayerElement>;
  changeLayout: (layout: Layout) => void;
}

export const useMeasurementControl = (options: UseMeasurementControlOptions) => {
  const { instance, playerRef, changeLayout } = options;

  const { setValue } = useFormContext();
  const disclosure = useDisclosure();
  const [step, setStep] = React.useState<MeasurementControlStep>('typeSelection');
  const [reportTemplateVariableId, setReportTemplateVariableId] = React.useState<string | null>(
    null,
  );

  const { reportTemplate } = useReport();

  const isOpen = disclosure.isOpen;

  const [containerWidth, containerHeight] = getContainedSize(playerRef.current);
  const imageWidth = instance?.dicom?.columns ?? 1;
  const imageHeight = instance?.dicom?.rows ?? 1;

  // Scale the canvas by the ratio of the image dimensions to the canvas dimensions
  const scale = {
    x: containerWidth / imageWidth,
    y: containerHeight / imageHeight,
  };

  const dimensions: Dimensions = {
    scale,
    imageWidth,
    imageHeight,
    containerWidth,
    containerHeight,
  };

  const ultrasoundRegions = React.useMemo(() => {
    const sequenceOfUltrasoundRegions = instance?.dicom.sequenceOfUltrasoundRegions ?? [];

    return sequenceOfUltrasoundRegions.map((item) => new UltrasoundRegion(item));
  }, [instance]);

  const measurementInfo = React.useMemo(() => {
    const reportTemplateVariable = reportTemplate.variables.find(
      (variable) => variable.id === reportTemplateVariableId,
    );

    if (
      !reportTemplateVariable ||
      reportTemplateVariable?.__typename !== 'ReportTemplateStaticVariable'
    ) {
      return null;
    }

    if (!reportTemplateVariable.units) {
      return null;
    }

    return {
      units: reportTemplateVariable.units,
      precision: reportTemplateVariable.precision ?? 2,
    };
  }, [reportTemplate.variables, reportTemplateVariableId]);

  const lengthTool = useLengthTool({ scale, ultrasoundRegions, measurementInfo });
  const areaTool = useAreaTool({ scale, ultrasoundRegions, measurementInfo });
  const volumeTool = useAreaLengthVolumeTool({ scale, ultrasoundRegions, measurementInfo });

  const activeTool: MeasurementTool | null = React.useMemo(() => {
    if (!reportTemplateVariableId) {
      return null;
    }

    switch (step) {
      case 'linear':
        return lengthTool;
      case 'area':
        return areaTool;
      case 'volume':
        return volumeTool;
      case 'typeSelection':
        return null;
    }
  }, [reportTemplateVariableId, step, lengthTool, areaTool, volumeTool]);

  const onReset = () => {
    setStep('typeSelection');
    setReportTemplateVariableId(null);
    if (activeTool) {
      activeTool?.reset();
    }
  };

  const onOpen = () => {
    setStep('typeSelection');
    disclosure.onOpen();
  };

  const onClose = () => {
    disclosure.onClose();
    onReset();
  };

  const onSelectStep = React.useCallback(
    (type: MeasurementControlStep) => {
      setStep(type);
      if (type !== 'typeSelection') {
        changeLayout(Layout.layout1x1);
      }
    },
    [changeLayout],
  );

  const onSelectReportTemplateVariableId = React.useCallback(
    (id: string) => {
      setReportTemplateVariableId(id);

      if (activeTool) {
        activeTool.reset();
      }
    },
    [activeTool],
  );

  const onSaveMeasurement = () => {
    if (!activeTool) {
      return;
    }

    if (!activeTool.measurement?.value) {
      return;
    }

    setValue(`variables.${reportTemplateVariableId}.value`, activeTool.measurement.value, {
      shouldDirty: true,
    });
  };

  return {
    isOpen,
    step,
    reportTemplateVariableId,
    ultrasoundRegions,
    dimensions,
    activeTool,
    lengthTool,
    areaTool,
    volumeTool,
    onOpen,
    onClose,
    onReset,
    onSelectStep,
    onSelectReportTemplateVariableId,
    onSaveMeasurement,
  };
};

export type UseMeasurementControlReturn = ReturnType<typeof useMeasurementControl>;
