import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { ControlTooltip } from './ControlTooltip';

export const OpenInNewTabControl = () => {
  return (
    <NavLink to="#" target="_blank">
      {() => (
        <ControlTooltip label="Open in new tab">
          <IconButton
            aria-label="Open in new tab"
            icon={<FaExternalLinkAlt />}
            size="sm"
            fontSize="xl"
            variant="imageViewer"
            colorScheme="purple"
          />
        </ControlTooltip>
      )}
    </NavLink>
  );
};
