import { LooseObject } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useRafLoop } from 'react-use';
import { Spin } from '@piccolohealth/ui';
import { Box, forwardRef, useMergeRefs } from '@chakra-ui/react';
import { Synchronizer } from '../utils/synchronizer';

interface Props {
  src: string;
  isPlaying: boolean;
  playbackRate: number;
  duration: number;
  frameRate: number;
  numberOfFrames: number;
  metadata: LooseObject;
  synchronizer: Synchronizer;
  onScreenshot?: (value: string, metadata: LooseObject) => void;
}

export const Mp4Player = forwardRef<Props, 'video'>((props: Props, forwardedRef) => {
  const {
    src,
    isPlaying,
    playbackRate,
    duration,
    frameRate,
    numberOfFrames,
    metadata,
    synchronizer,
    onScreenshot,
  } = props;

  const ref = React.useRef<HTMLVideoElement>(null);
  const mergedRef = useMergeRefs(ref, forwardedRef);

  const [isLoading, setIsLoading] = React.useState(true);

  const takeScreenshot = React.useCallback(() => {
    if (ref.current && onScreenshot) {
      const video = ref.current;
      let canvas: HTMLCanvasElement | null = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL('image/jpeg');
      onScreenshot?.(image, metadata);
      canvas = null;
    }
  }, [onScreenshot, metadata]);

  const setTime = React.useCallback(
    (targetTime: number) => {
      const videoElement = ref.current;

      if (!videoElement) {
        return;
      }

      videoElement.currentTime = targetTime;
    },
    [ref],
  );

  const setFrameRate = React.useCallback(
    (targetFrameRate: number) => {
      const videoElement = ref.current;

      if (!videoElement) {
        return;
      }

      videoElement.playbackRate = _.clamp(targetFrameRate / frameRate, 0.0625, 8);
    },
    [ref, frameRate],
  );

  const getTime = React.useCallback(() => {
    const videoElement = ref.current;

    if (!videoElement) {
      return 0;
    }

    return videoElement.currentTime;
  }, [ref]);

  const [loopStop, loopStart] = useRafLoop(() => {
    const videoElement = ref.current;

    if (videoElement && !videoElement.paused) {
      synchronizer.onPlaybackProgress(src, videoElement.currentTime, playbackRate);
    }
  }, false);

  React.useEffect(() => {
    if (ref.current) {
      const videoElement = ref.current;

      // Speed
      videoElement.playbackRate = playbackRate;

      // Play and pause
      if (isPlaying) {
        videoElement.play().catch(_.noop);
      } else {
        videoElement.pause();
        takeScreenshot();
      }

      //Hide the video element if we are still loading, in order to show spinner
      videoElement.hidden = isLoading;
    }
  }, [ref, playbackRate, isPlaying, isLoading, takeScreenshot]);

  React.useEffect(() => {
    let videoElement: HTMLVideoElement | null = null;

    if (ref.current) {
      videoElement = ref.current;
    }

    if (videoElement) {
      videoElement.onloadeddata = () => {
        setIsLoading(false);
        // Register with the synchronizer
        synchronizer.register({
          id: src,
          duration: duration / 1000,
          numberOfFrames: numberOfFrames,
          frameRate: frameRate,
          currentTime: 0,
          setFrameRate,
          setTime,
          getTime,
        });
      };

      videoElement.onplaying = () => {
        loopStart();
      };

      videoElement.onpause = () => {
        takeScreenshot();
      };

      videoElement.onseeked = () => {
        takeScreenshot();
      };
    }

    return () => {
      if (videoElement) {
        // Clean up
        videoElement.onloadeddata = null;
        videoElement.onplay = null;
        videoElement.onpause = null;
        loopStop();
        synchronizer.deregister(src);
      }
    };
  }, [
    duration,
    frameRate,
    getTime,
    loopStart,
    loopStop,
    numberOfFrames,
    setFrameRate,
    setTime,
    src,
    synchronizer,
    takeScreenshot,
  ]);

  const attrs: React.VideoHTMLAttributes<HTMLVideoElement> = {
    src,
    preload: 'autoPlay',
    crossOrigin: 'anonymous',
    autoPlay: true,
    muted: true,
    loop: true,
    hidden: true,

    playsInline: true,
    style: {
      height: '100%',
      width: '100%',
      display: isLoading ? 'none' : 'block',
      backgroundColor: 'transparent',
      background: 'transparent',
      pointerEvents: 'none',
    },
  };

  return (
    <Box w="full" h="full" bg="black">
      {isLoading ? <Spin /> : null}
      <video ref={mergedRef} {...attrs} />
    </Box>
  );
});
