import gql from 'graphql-tag';

export const MedicalObjectsIntegrationSettings = gql`
  query MedicalObjectsIntegrationSettings($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      users {
        id
        name
      }
      integrations {
        medicalObjects {
          id
          organizationId
          settings {
            enabled
            capricornUrl
            practiceId
            practiceName
            providerMappings {
              userId
              providerNumber
            }
            hl7Overrides {
              clinicalArea
            }
          }
        }
      }
    }
  }
`;

export const MedicalObjectsIntegrationPracticeProviders = gql`
  query MedicalObjectsIntegrationPracticeProviders($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      users {
        id
        name
      }
      integrations {
        medicalObjects {
          id
          organizationId
          practiceProviders {
            id
            firstName
            middleName
            lastName
            prefix
            suffix
            providerNumbers
            address {
              city
              state
            }
          }
        }
      }
    }
  }
`;

export const MedicalObjectsIntegrationProviders = gql`
  query MedicalObjectsIntegrationProviders(
    $organizationId: ID!
    $request: PaginatedMedicalObjectsProvidersRequest
  ) {
    organization(organizationId: $organizationId) {
      integrations {
        medicalObjects {
          id
          organizationId
          providers(request: $request) {
            pagination {
              total
              remaining
              offset
              limit
              currentPage
              totalPages
              hasNextPage
              hasPreviousPage
            }
            results {
              id
              firstName
              middleName
              lastName
              prefix
              suffix
              providerNumbers
              address {
                city
                state
              }
            }
          }
        }
      }
    }
  }
`;

export const HealthLinkIntegrationSettings = gql`
  query HealthLinkIntegrationSettings($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      users {
        id
        name
      }
      integrations {
        healthLink {
          id
          organizationId
          settings {
            enabled
            providerMappings {
              userId
              providerNumber
            }
            hl7Overrides {
              clinicalArea
            }
          }
        }
      }
    }
  }
`;

export const HealthLinkIntegrationPractitioners = gql`
  query HealthLinkIntegrationPractitioners(
    $organizationId: ID!
    $request: PaginatedHealthLinkPractitionersRequest
  ) {
    organization(organizationId: $organizationId) {
      integrations {
        healthLink {
          id
          organizationId
          practitioners(request: $request) {
            pagination {
              total
              remaining
              offset
              limit
              currentPage
              totalPages
              hasNextPage
              hasPreviousPage
            }
            results {
              id
              name {
                text
                family
                given
                prefix
                suffix
              }
              providers {
                providerNumber
                organization {
                  id
                  name
                  address {
                    city
                    country
                    line
                    postalCode
                    state
                    text
                    type
                    use
                  }
                }
                endpoint {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FaxIntegrationSettings = gql`
  query FaxIntegrationSettings($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      users {
        id
        name
      }
      integrations {
        fax {
          id
          organizationId
          settings {
            enabled
          }
        }
      }
    }
  }
`;
