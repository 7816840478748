import { StyleFunctionProps, getColor, mode } from '@chakra-ui/theme-tools';

const defaultProps = {
  size: 'sm',
  lineHeight: '1',
};

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode('purple.500', 'purple.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  };
}

const variants = {
  unstyled: () => ({
    h: 'auto',
    minH: 'auto',
    py: 0,
    verticalAlign: 'inherit',
    _focusVisible: {
      shadow: 'none',
      bg: 'gray.100',
    },
  }),
  outline: () => ({
    _hover: {
      bg: 'white',
    },
    _active: {
      bg: 'inherit',
      borderColor: 'gray.400',
    },
  }),

  imageViewer: (props: StyleFunctionProps) => ({
    ...props.theme.components.Button.variants.ghost(props),
    _hover: {
      bg: 'purple.600',
    },
    _active: {
      bg: 'purple.400',
    },
    color: 'white',
  }),

  selectGhost: (props: StyleFunctionProps) => ({
    ...props.theme.components.Button.variants.ghost(props),
    bg: 'white',
    _hover: {
      bg: 'gray.100',
      _disabled: { bg: 'gray.100', cursor: 'not-allowed' },
    },
    _focus: {
      bg: 'gray.100',
    },
    _focusVisible: {
      shadow: 'none',
      bg: 'gray.100',
    },
    _focusWithin: {
      bg: 'gray.100',
    },
    _groupFocusWithin: {
      bg: 'gray.100',
    },
    _expanded: {
      bg: 'gray.100',
    },
    _disabled: {
      opacity: 1,
      cursor: 'not-allowed',
    },
  }),
  selectOutline: (props: StyleFunctionProps) => {
    const { focusBorderColor } = getDefaults(props);

    return {
      ...props.theme.components.Button.variants.outline(props),
      bg: 'white',
      h: 'auto',
      py: '4px',
      minH: 8,
      textAlign: 'start',
      justifyContent: 'start',
      fontWeight: 'normal',
      _hover: {
        bg: 'white',
        borderColor: 'gray.300',
      },
      _focus: {
        bg: 'white',
        borderColor: 'gray.400',
      },
      _focusWithin: {
        bg: 'white',
        borderColor: 'gray.400',
      },
      _expanded: {
        borderColor: 'gray.300',
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: getColor(props.theme, focusBorderColor),
        boxShadow: `0 0 0 1px ${getColor(props.theme, focusBorderColor)}`,
      },
      _groupFocusWithin: {
        borderColor: getColor(props.theme, focusBorderColor),
        boxShadow: `0 0 0 1px ${getColor(props.theme, focusBorderColor)}`,
      },
    };
  },
};

export const Button = {
  defaultProps,
  variants,
};
