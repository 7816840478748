import { inflection, P } from '@piccolohealth/util';
import _ from 'lodash';
import {
  HealthLinkAddress,
  HealthLinkName,
  MedicalObjectsIntegrationProvider,
  SubscriptionItemAggregateType,
  View,
} from '../graphql';
import { exhaustiveMatchingGuard, JSONObject, JSONValue } from './generic';

const NO_SPACE_UNITS = ['%'];

export const renderFormattedValue = (
  value: JSONValue,
  units?: string | null,
  precision?: number | null,
): string | JSONObject => {
  let formattedValue: string | JSONObject = '';

  if (P.isNil(value)) {
    return '';
  } else if (_.isString(value)) {
    formattedValue = value;
  } else if (_.isNumber(value)) {
    // if there's a precision, render the value with that precision.
    // Eg if value === 4.0, precision === 1, render 4.0 as '4.0' not as '4'
    formattedValue = !P.isNil(precision) ? value.toFixed(precision) : value.toString();
  } else if (_.isBoolean(value)) {
    formattedValue = value.toString();
  } else if (_.isArray(value)) {
    formattedValue = value.join(', ');
  } else if (_.isPlainObject(value)) {
    // Deliberately not handling objects. Pass them through as-is.
    // lvWm is an example
    formattedValue = value;
  }

  // No units
  if (_.isNil(units)) {
    return formattedValue;
  }

  // No space formatting
  if (_.includes(NO_SPACE_UNITS, units)) {
    return `${formattedValue}${units}`;
  }

  // Single space formatting
  return `${formattedValue} ${units}`;
};

export const renderCurrency = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  currencyDisplay: 'symbol',
  maximumFractionDigits: 2,
}).format;

export const renderMedicalObjectsProviderName = (
  provider: MedicalObjectsIntegrationProvider,
): string => {
  return _.compact([
    inflection.humanize(provider.prefix ?? ''),
    inflection.humanize(provider.firstName ?? ''),
    inflection.humanize(provider.middleName ?? ''),
    inflection.humanize(provider.lastName ?? ''),
    inflection.humanize(provider.suffix ?? ''),
  ]).join(' ');
};

export const renderMedicalObjectsProviderAddress = (
  provider: MedicalObjectsIntegrationProvider,
): string | null => {
  const address = _.head(provider.address);

  if (!address) {
    return null;
  }

  return _.compact([
    address.streetAddress && inflection.titleize(address.streetAddress),
    address.city && inflection.titleize(address.city),
    address.state && _.toUpper(address.state),
    address.postCode,
  ]).join(', ');
};

export const renderHealthLinkName = (name: HealthLinkName): string => {
  return _.compact([
    inflection.humanize(_.join(name.prefix, ' ')),
    inflection.humanize(_.join(name.given, ' ')),
    inflection.humanize(name.family ?? ''),
    inflection.humanize(_.join(name.suffix, ' ')),
  ]).join(' ');
};

export const renderHealthLinkAddress = (address: HealthLinkAddress): string => {
  return _.chain([
    ...(address.line ?? []),
    address.city && inflection.titleize(address.city),
    address.state && _.toUpper(address.state),
    address.postalCode,
  ])
    .compact()
    .map(_.trim)
    .join(', ')
    .value();
};

export const renderSexShort = (value: string) => {
  switch (value.toLowerCase()) {
    case 'm':
    case 'male':
      return 'M';
    case 'f':
    case 'female':
      return 'F';
    default:
      return 'U';
  }
};

export const getNameComponents = (name: string): [string, string] => {
  const [firstName, ...rest] = _.split(name, ' ');
  const lastName = rest.join(' ');

  return [firstName, lastName];
};

export const renderDicomView = (view: View): string => {
  switch (view) {
    case View.Ap2:
      return 'AP2';
    case View.Ap3:
      return 'AP3';
    case View.Ap4:
      return 'AP4';
    case View.Plax:
      return 'PLAX';
    case View.PsaxBasal:
      return 'PSAX BASAL';
    case View.PsaxMid:
      return 'PSAX MID';
    case View.PsaxApical:
      return 'PSAX AP';
    case View.Unknown:
      return 'Unknown';
    default:
      return exhaustiveMatchingGuard(view);
  }
};

export const renderSubscriptionItemAggregateType = (
  type: SubscriptionItemAggregateType,
): string => {
  switch (type) {
    case SubscriptionItemAggregateType.Sum:
      return 'Sum';
    case SubscriptionItemAggregateType.LastValue:
      return 'Last Value';
  }
};
