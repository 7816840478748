import { DateTime } from '@piccolohealth/util';
import { EChartsOption, LineSeriesOption } from 'echarts';
import React from 'react';
import { CHART_COLORS } from '../../components/charts/ChartsTheme';
import { ECharts } from '../../components/charts/ECharts';
import { CreateMediaAttachmentChartRequest } from '../../components/templates/report/components/attachments/media/MediaAttachmentZone';

export type MeasurementSeries = {
  id: string;
  data: { x: string | number; y: number | null }[];
};
export type MeasurementChartData = {
  series: MeasurementSeries[];
  units: string | null;
  color: string;
};

/**
 * Calculate the settings for the Y-axis based on the maximum data value
 * ECharts is unable to automatically calculate the Y-axis settings based
 * in a nice way. So we need to calculate the min, max and interval manually.
 * The goal is to have a nice interval that divides up into a set number of dmarks
 */
const calculateYAxisSettings = (
  maxDataValue: number,
  numberOfMarks = 10,
  modifier = 1.4,
): {
  max: number;
  min: number;
  interval: number;
} => {
  const niceNumbers = [0.1, 0.2, 0.25, 0.5, 1, 2, 2.5, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000];

  const idealMax = maxDataValue * modifier;
  const idealInterval = idealMax / numberOfMarks;

  const interval = niceNumbers.reduce((prev, curr) => {
    return Math.abs(curr - idealInterval) < Math.abs(prev - idealInterval) ? curr : prev;
  }, 0);

  const min = 0;
  const max = Math.ceil(idealMax / interval) * interval;

  return { min, max, interval };
};

interface Props {
  data: CreateMediaAttachmentChartRequest['data'];
  ssr: boolean;
  width?: number;
  height?: number;
}

export const MeasurementsChart = (props: Props) => {
  const combinedData = React.useMemo(() => {
    const allXValues = Array.from(
      new Set(props.data.flatMap((d) => d.series.flatMap((s) => s.data.map((point) => point.x)))),
    );

    return props.data.map((d) => ({
      color: d.color || CHART_COLORS.green, // Default color if not specified
      units: d.units,
      series: d.series.map((s) => ({
        ...s,
        data: allXValues.map((x) => {
          const found = s.data.find((point) => point.x === x);
          return found ? found : { x, y: null };
        }),
      })),
    }));
  }, [props.data]);

  const options: EChartsOption = React.useMemo(() => {
    const series: LineSeriesOption[] = combinedData.map((d, index) => ({
      type: 'line',
      name: d.series[0].id,
      data: d.series[0].data.map((point) => ({
        name: point.x as string,
        value: point.y,
      })),
      lineStyle: { color: d.color },
      itemStyle: { color: d.color },
      symbolSize: 8,
      symbol: 'emptyCircle',
      smooth: true,
      yAxisIndex: index, // Assign the series to the appropriate Y-axis (0 for left, 1 for right)
    }));

    const xAxisData = combinedData[0]?.series[0].data.map((point) =>
      DateTime.fromISO(point.x as string).toFormat('MM/yy'),
    );

    const leftYAxisSettings = calculateYAxisSettings(
      Math.max(...(combinedData?.[0].series?.[0]?.data?.map((v) => v.y ?? 0) ?? [0])),
    );

    const rightYAxisSettings = calculateYAxisSettings(
      Math.max(...(combinedData?.[1]?.series?.[0].data?.map((v) => v.y ?? 0) ?? [0])),
    );

    return {
      grid: {
        top: 10,
        left: 20,
        right: 20,
        bottom: 10,
        show: true,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const formatterParams = params as {
            data: { name: string; value: number | null };
            marker: string;
            seriesName: string;
          }[];

          const date = DateTime.fromISO(formatterParams[0]?.data?.name);
          const formattedDate = date.toFormat('dd/MM/yyyy');
          const relativeDate = date.toRelative();

          return `
           ${formattedDate} ${relativeDate}
            <br/>
            ${formatterParams
              .map((p: any) => {
                return `${p.marker} <strong>${p.seriesName}:</strong> ${p.data.value ?? 'N/A'} ${
                  combinedData[p.seriesIndex]?.units ?? ''
                }`;
              })
              .join('<br/>')}`;
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: false,
        axisLine: { show: false, lineStyle: { color: CHART_COLORS.gray } },
      },
      yAxis: [
        {
          type: 'value',
          axisLine: { show: true, lineStyle: { color: combinedData[0]?.color, width: 2 } },
          axisLabel: {
            color: CHART_COLORS.gray,
            formatter: (value) => `${value} ${combinedData[0]?.units || ''}`,
          },
          min: leftYAxisSettings.min,
          max: leftYAxisSettings.max,
          interval: leftYAxisSettings.interval,
        },
        {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: { color: combinedData[1]?.color ?? CHART_COLORS.lightGray, width: 2 },
          },
          axisLabel: {
            color: CHART_COLORS.gray,
            formatter: (value) => `${value} ${combinedData[1]?.units || ''}`,
          },
          splitLine: { show: false }, // Disable grid lines for the right Y-axis
          min: rightYAxisSettings.min,
          max: rightYAxisSettings.max,
          interval: rightYAxisSettings.interval,
        },
      ],
      series,
      animationDuration: 400,
    };
  }, [combinedData]);

  return <ECharts options={options} width={props.width} height={props.height} />;
};
