import { Card, CardBody, CardHeader, Divider, Heading, HStack, Stack } from '@chakra-ui/react';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { ScrollArea, Spin } from '@piccolohealth/ui';
import React from 'react';
import { useQuery } from 'react-query';
import { Content } from '../../components/generic/Content';
import { CubeBarTimeChart } from '../../features/insights/components/CubeBarTimeChart';
import { CubeFilters } from '../../features/insights/components/CubeFilters';
import { CubePieChart } from '../../features/insights/components/CubePieChart';
import { CubeTable } from '../../features/insights/components/CubeTable';
import { CubeValue } from '../../features/insights/components/CubeValue';
import { useCubeQuery } from '../../features/insights/hooks/useCubeQuery';
import { useCubeQueryFilter } from '../../features/insights/hooks/useCubeQueryFilter';
import { queries } from '../../features/insights/queries';
import { useAppContext } from '../../hooks/useAppContext';

export const Insights = () => {
  const { config, auth, organization } = useAppContext();

  const filter = useCubeQueryFilter();
  const { withTimeRange } = useCubeQuery(filter);

  const { isLoading, data: token } = useQuery<string>(
    ['access-token'],
    auth.getAccessTokenSilently,
  );

  if (isLoading || !token) {
    return <Spin />;
  }

  const cubejsApi = cubejs('piccolo', {
    apiUrl: config.api.cubeJs,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-OrganizationId': organization.id,
    },
  });

  return (
    <Content title="Insights">
      <CubeProvider cubejsApi={cubejsApi}>
        <Stack w="full" h="full" p={4} spacing={4} minH={0}>
          <CubeFilters filter={filter} />
          <Divider />

          <HStack w="full" h="full" spacing={4} minH={0}>
            <Stack w="full" h="full" flexGrow={1} spacing={4}>
              <HStack w="full" spacing={4}>
                <Card
                  w="full"
                  minW="180px"
                  minH="120px"
                  size="sm"
                  borderWidth="1px"
                  borderColor="gray.100"
                  rounded="xl"
                >
                  <CardHeader>
                    <Heading size="sm">Reports uploaded</Heading>
                  </CardHeader>
                  <CardBody>
                    <CubeValue query={withTimeRange(queries.reportsUploadedCount(filter))} />
                  </CardBody>
                </Card>
                <Card
                  w="full"
                  minW="180px"
                  minH="120px"
                  size="sm"
                  borderWidth="1px"
                  borderColor="gray.100"
                  rounded="xl"
                >
                  <CardHeader>
                    <Heading size="sm">Reports finalized</Heading>
                  </CardHeader>
                  <CardBody>
                    <CubeValue query={withTimeRange(queries.reportsFinalizedCount(filter))} />
                  </CardBody>
                </Card>
                <Card
                  w="full"
                  minW="180px"
                  minH="120px"
                  size="sm"
                  borderWidth="1px"
                  borderColor="gray.100"
                  rounded="xl"
                >
                  <CardHeader>
                    <Heading size="sm">Reports shared</Heading>
                  </CardHeader>
                  <CardBody>
                    <CubeValue query={withTimeRange(queries.reportsSharedCount(filter))} />
                  </CardBody>
                </Card>
                <Card
                  w="full"
                  minW="180px"
                  minH="120px"
                  size="sm"
                  borderWidth="1px"
                  borderColor="gray.100"
                  rounded="xl"
                >
                  <CardHeader>
                    <Heading size="sm">Unique patients</Heading>
                  </CardHeader>
                  <CardBody>
                    <CubeValue
                      query={withTimeRange(queries.reportsFinalizedByUniquePatientName(filter))}
                    />
                  </CardBody>
                </Card>
              </HStack>

              <Card
                w="full"
                h="full"
                size="sm"
                borderWidth="1px"
                borderColor="gray.100"
                rounded="xl"
              >
                <CardHeader>
                  <Heading size="sm">Reports uploaded</Heading>
                </CardHeader>
                <CubeBarTimeChart query={withTimeRange(queries.reportsUploadedOverTime(filter))} />
              </Card>
            </Stack>
            <ScrollArea w="sm" h="full" flexShrink={0}>
              <Stack>
                <Card borderWidth="1px" borderColor="gray.100" minH="2xs" rounded="xl">
                  <CardHeader>
                    <Heading size="sm">Sonographers</Heading>
                  </CardHeader>
                  <CardBody p={0}>
                    <CubeTable
                      query={withTimeRange(queries.reportsBySonographer(filter))}
                      limit={10}
                    />
                  </CardBody>
                </Card>

                <Card borderWidth="1px" borderColor="gray.100" minH="2xs" rounded="xl">
                  <CardHeader>
                    <Heading size="sm">Cardiologists</Heading>
                  </CardHeader>
                  <CardBody p={0}>
                    <CubeTable
                      query={withTimeRange(queries.reportsByCardiologist(filter))}
                      limit={10}
                    />
                  </CardBody>
                </Card>

                <Card borderWidth="1px" borderColor="gray.100" minH="2xs" rounded="xl">
                  <CardHeader>
                    <Heading size="sm">Referring doctors</Heading>
                  </CardHeader>
                  <CardBody p={0}>
                    <CubeTable
                      query={withTimeRange(queries.reportsByReferrer(filter))}
                      limit={10}
                    />
                  </CardBody>
                </Card>

                <Card borderWidth="1px" borderColor="gray.100" h="xs" rounded="xl">
                  <CardHeader>
                    <Heading size="sm">Report types</Heading>
                  </CardHeader>
                  <CardBody py={0}>
                    <CubePieChart
                      query={withTimeRange(queries.reportsByReportTemplateName(filter))}
                    />
                  </CardBody>
                </Card>
              </Stack>
            </ScrollArea>
          </HStack>
        </Stack>
      </CubeProvider>
    </Content>
  );
};
