import React from 'react';

export type HTMLPlayerElement = HTMLImageElement | HTMLVideoElement;

export const usePlayerRefs = () => {
  const playerRef1 = React.useRef<HTMLPlayerElement>(null);
  const playerRef2 = React.useRef<HTMLPlayerElement>(null);
  const playerRef3 = React.useRef<HTMLPlayerElement>(null);
  const playerRef4 = React.useRef<HTMLPlayerElement>(null);

  return [playerRef1, playerRef2, playerRef3, playerRef4];
};
