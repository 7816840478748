import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { FaRulerVertical } from 'react-icons/fa';
import { ControlTooltip } from './ControlTooltip';

interface Props {
  isMeasurementsShowing: boolean;
  onToggleMeasurementsShowing: () => void;
}

export const ToggleMeasurementsControl = (props: Props) => {
  const { isMeasurementsShowing, onToggleMeasurementsShowing } = props;

  return (
    <ControlTooltip label={isMeasurementsShowing ? 'Hide measurements' : 'Show measurements'}>
      <IconButton
        aria-label="Toggle measurements"
        icon={<FaRulerVertical size="28px" />}
        onClick={onToggleMeasurementsShowing}
        size="sm"
        fontSize="xl"
        variant="imageViewer"
        colorScheme="purple"
        isActive={isMeasurementsShowing}
        color="purple.500"
        _hover={{
          color: 'purple.300',
        }}
        _active={{
          color: 'purple.300',
        }}
      />
    </ControlTooltip>
  );
};
