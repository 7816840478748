import { useAuth0 } from '@auth0/auth0-react';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import { P, Settings as DateTimeSettings } from '@piccolohealth/util';
import {
  FeatureFlag,
  hasFeature as organizationHasFeature,
  IntegrationType,
  Organization,
  OrganizationMembership,
  Permission,
  PiccoloError,
  withPermission,
} from '@piccolohealth/echo-common';
import * as Sentry from '@sentry/react';
import _ from 'lodash';
import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { Error } from '../components/generic/Error';
import { useOrganizationQuery } from '../graphql/hooks/useOrganizationQuery';
import { useUser } from './UserContext';

export interface OrganizationContext extends Organization {
  organizationMembership: OrganizationMembership;
  hasFeature: (featureFlag: FeatureFlag) => boolean;
  hasPermission: (permission: Permission) => Promise<boolean>;
  hasIntegrationEnabled: (type: IntegrationType) => boolean;
  isLoading: boolean;
}

export const OrganizationContext = React.createContext<OrganizationContext>(null as any);

export const OrganizationContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { organizationId } = useRequiredParams<{ organizationId: string }>();
  const { isAuthenticated } = useAuth0();
  const { update: updateIntercom } = useIntercom();
  const user = useUser();

  const organizationMembership = _.find(
    user?.organizationMemberships,
    (om) => om.organizationId === organizationId,
  ) as OrganizationMembership;

  const { isLoading, fetchStatus, data, error } = useOrganizationQuery(
    { organizationId },
    { enabled: isAuthenticated && !_.isUndefined(user) && !_.isUndefined(organizationMembership) },
  );

  const organization = data?.organization as Organization;

  React.useEffect(() => {
    if (isAuthenticated && !isLoading && user && organization) {
      DateTimeSettings.defaultLocale = 'en-AU';
      DateTimeSettings.defaultZone = organization.timezone;

      Sentry.setUser({
        id: user.id,
        username: user.name,
        email: user.email,
        organizationId: organization.id,
      });

      Sentry.setTags({
        username: user.name,
        email: user.email,
        organization: organization.name,
      });

      const nonSharedOrganizationMemberships = _.chain(user.organizationMemberships)
        .filter((om) => !_.includes(_.map(om.roles, 'name'), 'Shared'))
        .value();

      updateIntercom({
        company: {
          companyId: organization.id,
          name: organization.name,
        },
        companies: _.map(nonSharedOrganizationMemberships, (organizationMembership) => ({
          companyId: organizationMembership.organizationId,
          name: organizationMembership.name,
        })),
      });

      document.title = `Piccolo Health - ${organization.name}`;
    }
  }, [organization, user, isAuthenticated, isLoading, updateIntercom]);

  const hasFeature = (featureFlag: FeatureFlag) => {
    return organizationHasFeature(organization?.featureFlags ?? [], featureFlag);
  };

  const hasPermission = async (permission: Permission): Promise<boolean> => {
    if (isLoading || user.isLoading) {
      return false;
    }

    try {
      return withPermission(user, organization?.id, _.constant(true), permission);
    } catch (error) {
      return false;
    }
  };

  const hasIntegrationEnabled = (type: IntegrationType) => {
    switch (type) {
      case IntegrationType.MedicalObjects: {
        return organization.integrations.medicalObjects.settings.enabled;
      }
      case IntegrationType.HealthLink: {
        return organization.integrations.healthLink.settings.enabled;
      }
      case IntegrationType.Fax: {
        return organization.integrations.fax.settings.enabled;
      }
    }
  };

  const value: OrganizationContext = {
    organizationMembership,
    hasFeature,
    hasPermission,
    hasIntegrationEnabled,
    isLoading,
    ...organization,
  };

  const content = P.run(() => {
    if (error) {
      return <Error error={error} />;
    }

    if (isLoading && fetchStatus != 'idle') {
      return <Spin />;
    }

    if (!organizationMembership) {
      return (
        <Error
          error={
            new PiccoloError({
              type: 'Unauthorized',
              message: 'Sorry, you are not authorized to access this resource',
            })
          }
        />
      );
    }

    return children;
  });

  return <OrganizationContext.Provider value={value}>{content}</OrganizationContext.Provider>;
};

export const useOrganization: () => OrganizationContext = () => {
  return React.useContext(OrganizationContext);
};
