import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import { UseImageViewerActionsReturn } from '../hooks/useImageViewerActions';
import { ImageViewerOptions } from '../ImageViewer';
import { FullScreenControl } from './FullScreenControl';
import { LayoutControl } from './LayoutControl';
import { ModeControl } from './ModeControl';
import { OpenInNewTabControl } from './OpenInNewTabControl';
import { PlayControls } from './PlayControls';
import { SettingsControl } from './SettingsControl';
import { ToggleMeasurementsControl } from './ToggleMeasurementsControl';
import { ToggleReportControl } from './ToggleReportControl';

interface Props {
  options: ImageViewerOptions;
  actions: UseImageViewerActionsReturn;
}

export const ImageViewerControls = (props: Props) => {
  const { options, actions } = props;

  const {
    allowFullscreen = true,
    allowChangeLayout = true,
    allowChangeModes = true,
    allowShowReport = false,
    allowShowMeasurements = false,
  } = options;

  return (
    <HStack
      w="full"
      p={2}
      align="center"
      justify="space-between"
      bg="gray.900"
      borderBottomColor="gray.800"
      borderBottomWidth="1px"
      // Make the toolbar sit on top of Intercom, if toolbar is at the bottom
      zIndex={actions.toolbarPosition === 'bottom' ? 2147483002 : undefined}
    >
      <HStack flexShrink={0}>
        {allowFullscreen && (
          <FullScreenControl
            currentFullscreen={actions.isFullscreen}
            onChangeFullscreen={actions.changeFullscreen}
          />
        )}
        {allowChangeLayout && (
          <LayoutControl currentLayout={actions.layout} onChangeLayout={actions.changeLayout} />
        )}
        {allowShowMeasurements && (
          <ToggleMeasurementsControl
            isMeasurementsShowing={actions.isMeasurementsShowing}
            onToggleMeasurementsShowing={actions.toggleShowMeasurements}
          />
        )}
        {allowChangeModes && (
          <ModeControl
            hasStressInstances={actions.hasStressInstances}
            currentMode={actions.mode}
            onChangeMode={actions.changeMode}
          />
        )}
      </HStack>
      <Box>
        <PlayControls
          isPlaying={actions.isPlaying}
          onClickNextFrame={actions.nextFrame}
          onClickNextClip={actions.nextClip}
          onClickPlayPause={actions.playPause}
          onClickPreviousFrame={actions.previousFrame}
          onClickPreviousClip={actions.previousClip}
        />
      </Box>

      <HStack spacing={0}>
        <OpenInNewTabControl />
        <SettingsControl
          speed={actions.speed}
          onChangeSpeed={actions.changeSpeed}
          brightness={actions.brightness}
          onChangeBrightness={actions.changeBrightness}
          contrast={actions.contrast}
          onChangeContrast={actions.changeContrast}
          onChangeToolbarPosition={actions.changeToolbarPosition}
          toolbarPosition={actions.toolbarPosition}
        />
        {allowShowReport && (
          <ToggleReportControl
            isReportShowing={actions.isReportShowing}
            onToggleReportShowing={actions.toggleShowReport}
          />
        )}
      </HStack>
    </HStack>
  );
};
