import { ChakraV3 } from '@piccolohealth/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { timezoneList } from '@piccolohealth/util';
import { CreateOrganizationRequest, OrganizationStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormSection } from '../../../components/forms/FormSection';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedImageUploader } from '../../../components/forms/hookform/HookedImageUploader';
import { HookedInput } from '../../../components/forms/hookform/HookedInput';
import { HookedResetButton } from '../../../components/forms/hookform/HookedResetButton';
import { HookedSelect } from '../../../components/forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { Content } from '../../../components/generic/Content';
import { Divider } from '../../../components/generic/Divider';
import { useCreateOrganizationMutation } from '../../../graphql/hooks/useCreateOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';

export const Onboard = () => {
  const { user, successToast, errorToast } = useAppContext();

  const mutation = useCreateOrganizationMutation();

  const initialValues: CreateOrganizationRequest = {
    name: '',
    status: OrganizationStatus.Trial,
    addressLineOne: null,
    addressLineTwo: null,
    email: null,
    phone: null,
    logo: null,
    timezone: 'Australia/Brisbane',
    supportEmail: 'dom@piccolohealth.com',
    stripeCustomerId: null,
    stripeSubscriptionId: null,
    contract: null,
    copyTemplatesFrom: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    addressLineOne: Yup.string().nullable(),
    addressLineTwo: Yup.string().nullable(),
    email: Yup.string().email('Invalid email address').nullable(),
    phone: Yup.string().nullable(),
    logo: Yup.string().nullable(),
    timezone: Yup.string().required(),
    supportEmail: Yup.string().required(),
    stripeCustomerId: Yup.string().nullable(),
    stripeSubscriptionId: Yup.string().nullable(),
    contract: Yup.string().nullable(),
    copyTemplatesFrom: Yup.string().nullable(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema) as any,
  });

  const onSubmit = async (values: CreateOrganizationRequest) => {
    await mutation
      .mutateAsync({
        createOrganizationRequest: values,
      })
      .then(() => {
        successToast('Organization created successfully');
      })
      .catch((err) => {
        errorToast(`Error creating organization: ${err.message}`);
      });
  };

  const timezoneOptions = timezoneList.map((timezone) => ({
    value: timezone,
    label: timezone,
    raw: timezone,
  }));

  const organizationOptions = user.organizationMemberships.map((membership) => ({
    label: membership.name,
    value: membership.organizationId,
    raw: membership.organizationId,
  }));

  return (
    <Content title="Onboard">
      <ChakraV3.Box maxW="400px" p={4}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormSection heading="Create an organization">
              <HookedFormItem label="Company name" name="name">
                <HookedInput name="name" placeholder="Company name" />
              </HookedFormItem>
              <HookedFormItem label="Address Line 1" name="addressLineOne">
                <HookedInput name="addressLineOne" placeholder="Address Line 1" />
              </HookedFormItem>
              <HookedFormItem label="Address Line 2" name="addressLineTwo">
                <HookedInput name="addressLineTwo" placeholder="Address Line 2" />
              </HookedFormItem>
              <HookedFormItem label="Email" name="email">
                <HookedInput name="email" placeholder="Email" />
              </HookedFormItem>
              <HookedFormItem label="Phone" name="phone">
                <HookedInput name="phone" placeholder="Phone" />
              </HookedFormItem>
              <HookedFormItem label="Logo" name="logo">
                <HookedImageUploader name="logo" maxWidth={160} maxHeight={120} />
              </HookedFormItem>
              <HookedFormItem label="Timezone" name="timezone">
                <HookedSelect name="timezone" options={timezoneOptions} />
              </HookedFormItem>
              <HookedFormItem label="Support Email" name="supportEmail">
                <HookedInput name="supportEmail" placeholder="Support Email" />
              </HookedFormItem>
              <HookedFormItem label="Stripe Customer ID" name="stripeCustomerId">
                <HookedInput name="stripeCustomerId" placeholder="Stripe Customer ID" />
              </HookedFormItem>
              <HookedFormItem label="Stripe Subscription ID" name="stripeSubscriptionId">
                <HookedInput name="stripeSubscriptionId" placeholder="Stripe Subscription ID" />
              </HookedFormItem>
              <HookedFormItem label="Contract" name="contract">
                <HookedInput name="contract" placeholder="Contract URL" />
              </HookedFormItem>
              <HookedFormItem label="Pubsub Topic" name="pubsubTopic">
                <HookedInput name="pubsubTopic" placeholder="Pubsub Topic" />
              </HookedFormItem>
              <HookedFormItem label="Copy templates from" name="copyTemplatesFrom">
                <HookedSelect name="copyTemplatesFrom" options={organizationOptions} />
              </HookedFormItem>
            </FormSection>
            <Divider />
            <ChakraV3.HStack alignItems="center">
              <HookedResetButton>Reset</HookedResetButton>
              <HookedSubmitButton>Save</HookedSubmitButton>
            </ChakraV3.HStack>
          </form>
        </FormProvider>
      </ChakraV3.Box>
    </Content>
  );
};
