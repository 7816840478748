import { Box, Button, Flex, FlexProps, HStack, Image, Spacer, Stack, Text } from '@chakra-ui/react';
import { Stage, View } from '@piccolohealth/echo-common';
import { Dot } from '@piccolohealth/ui';
import React from 'react';
import { showModal } from '../../../../../generic/Modal';
import { AttachedMediaModal } from './AttachedMediaModal';
import { ChartAttachmentContent } from './chart/ChartAttachmentContent';
import { CreateMediaAttachmentRequest } from './MediaAttachmentZone';

interface Props extends Omit<FlexProps, 'title'> {
  ssr: boolean;
  title?: string | React.ReactElement;
  id: string;
  description?: string | null;
  metadata: JSX.Element;
  height: string;
  width: string;
  isDisabled: boolean;
  onClickRemove: (id: string) => void;
  onClickOpen: () => void;
}

const AttachedItem = (props: React.PropsWithChildren<Props>) => {
  const {
    children,
    ssr,
    title,
    id,
    description,
    metadata,
    width,
    height,
    isDisabled,
    onClickRemove,
    onClickOpen,
    ...rest
  } = props;

  const borderStyles = ssr ? { borderWidth: '1px', borderColor: 'gray.200' } : {};

  return (
    <Flex
      flexDir="column"
      alignItems="stretch"
      minW={width}
      h="full"
      {...borderStyles}
      rounded="md"
      overflow="hidden"
      {...rest}
    >
      <Flex
        flexBasis="auto"
        flexGrow={0}
        justifyContent="center"
        alignItems="center"
        h="full"
        minHeight={0}
        cursor="pointer"
        onClick={onClickOpen}
      >
        {children}
      </Flex>
      <Box flexGrow={0} flexShrink={0} flexBasis="auto">
        <Box position="absolute" top={4} right={4} zIndex={1}>
          {metadata}
        </Box>
        <HStack align="center" minH={8} py={1} px={2} borderTopWidth="1px" borderColor="gray.200">
          <Stack align="start" spacing={1}>
            {title && (
              <Text fontWeight="semibold" fontSize="2xs">
                {title}
              </Text>
            )}
            {description && (
              <Text color="gray.700" fontWeight="normal" fontSize="2xs">
                {description}
              </Text>
            )}
          </Stack>
          <Spacer />
          {!ssr ? (
            <HStack align="end" spacing={1}>
              <Button
                isDisabled={isDisabled}
                color="secondary"
                variant="link"
                onClick={() => onClickRemove(id)}
                fontSize="2xs"
              >
                Remove
              </Button>
            </HStack>
          ) : null}
        </HStack>
      </Box>
    </Flex>
  );
};

export const AttachedMedia = (props: {
  ssr: boolean;
  value: CreateMediaAttachmentRequest;
  isDisabled: boolean;
  onClickRemove: (id: string) => void;
}) => {
  const { ssr, value, isDisabled, onClickRemove } = props;

  switch (value.type) {
    case 'CHART': {
      const height = 315;
      const width = 420;

      const title = (
        <Stack spacing={0}>
          {value.data.map((v) => (
            <HStack key={v.series[0].id}>
              <Dot size="2" bg={v.color} />
              <Text fontSize="xs">{v.series[0].id}</Text>
            </HStack>
          ))}
        </Stack>
      );

      return (
        <AttachedItem
          ssr={ssr}
          id={value.id}
          metadata={<></>}
          title={title}
          description={value?.description}
          overflow="revert"
          width={`${width}px`}
          height={`${height}px`}
          isDisabled={isDisabled}
          onClickRemove={onClickRemove}
          onClickOpen={() =>
            showModal(AttachedMediaModal, {
              children: (
                <Stack>
                  <Box h="720px">
                    <ChartAttachmentContent ssr={false} data={value.data} />
                  </Box>
                  <Text fontSize="sm" fontWeight="semibold">
                    {title}
                  </Text>
                </Stack>
              ),
            })
          }
        >
          <ChartAttachmentContent ssr={ssr} data={value.data} width={width} height={height} />
        </AttachedItem>
      );
    }

    case 'STUDY_IMAGE': {
      const stage =
        value.metadata?.stage && value.metadata.stage !== Stage.Unknown
          ? value.metadata.stage
          : null;
      const view =
        value.metadata?.view && value.metadata.view !== View.Unknown ? value.metadata.view : null;

      const stageView =
        stage || view ? (
          <Text fontSize="sm" fontWeight="bold" color="white">
            {stage} {view}
          </Text>
        ) : (
          <></>
        );

      return (
        <AttachedItem
          ssr={ssr}
          id={value.id}
          description={value.description}
          metadata={stageView}
          height="auto"
          width="auto"
          isDisabled={isDisabled}
          onClickRemove={onClickRemove}
          onClickOpen={() =>
            showModal(AttachedMediaModal, {
              children: (
                <Flex justify="center" w="full" h="full">
                  <Image src={value.data} />
                  <Text fontSize="sm">{value.description}</Text>
                </Flex>
              ),
            })
          }
        >
          <Image
            w="full"
            maxH="full"
            src={value.data}
            fallbackSrc={value.data}
            borderTopRadius="lg"
          />
        </AttachedItem>
      );
    }

    case 'IMAGE_UPLOAD': {
      return (
        <AttachedItem
          ssr={ssr}
          id={value.id}
          description={value.description}
          metadata={<></>}
          height="100%"
          width="auto"
          isDisabled={isDisabled}
          onClickRemove={onClickRemove}
          onClickOpen={() =>
            showModal(AttachedMediaModal, {
              children: (
                <Flex justify="center" w="full" h="full">
                  <Image src={value.data} />
                  <Text fontSize="sm">{value.description}</Text>
                </Flex>
              ),
            })
          }
        >
          <Image w="full" maxH="full" src={value.data} />
        </AttachedItem>
      );
    }
  }
};
