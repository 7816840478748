import { Stack } from '@chakra-ui/react';
import { Instance } from '@piccolohealth/echo-common';
import React from 'react';
import { InstanceFormat } from '../controls/InstanceFormatTag';
import { Layout } from '../controls/Layout';
import { Viewport } from '../hooks/useImageViewerActions';
import { InstanceInfo } from '../measurements/utils';
import { InstanceInfoOverlay } from './InstanceInfoOverlay';
import { StressImageChooserOverlay } from './StressImageChooserOverlay';

interface Props {
  viewport: Viewport;
  gridIndex: number;
  instanceInfo: InstanceInfo;
  format: InstanceFormat;
  layout: Layout;
  onClickInstance: (instance: Instance) => void;
}

export const Overlay = React.memo((props: Props) => {
  const { viewport, gridIndex, instanceInfo, format, layout, onClickInstance } = props;

  const [left, right, align] = React.useMemo(() => {
    const odd = gridIndex % 2 === 1;
    const padding = 2;

    switch (layout) {
      case Layout.layout1x1:
        return [padding, undefined, 'start'];
      case Layout.layout1x2:
        return [odd ? undefined : padding, odd ? padding : undefined, odd ? 'end' : 'start'];
      case Layout.layout2x1:
        return [padding, padding, 'start'];
      case Layout.layout2x2:
        return [odd ? undefined : padding, odd ? padding : undefined, odd ? 'end' : 'start'];
    }
  }, [gridIndex, layout]);

  return (
    <Stack
      position="absolute"
      top={2}
      left={left}
      right={right}
      align={align}
      zIndex={1}
      userSelect="none"
    >
      <InstanceInfoOverlay info={instanceInfo} format={format} />
      <StressImageChooserOverlay viewport={viewport} onClickInstance={onClickInstance} />
    </Stack>
  );
});
