import React from 'react';
import { Rect, Text } from 'react-konva';
import { Vector2D } from '../utils';

const HEIGHT = 16;
const WIDTH = 100;

interface Props {
  value: string;
  position: Vector2D;
  color: string;
  showRect?: boolean;
}

export const MeasurementText = (props: Props) => {
  const { value, position, color, showRect = false } = props;

  return (
    <>
      {showRect && (
        <Rect
          x={position.x}
          y={position.y}
          offsetX={WIDTH / 2}
          offsetY={HEIGHT / 2}
          width={WIDTH}
          height={16}
          fill="blue"
        />
      )}
      <Text
        x={position.x - WIDTH / 2}
        y={position.y - HEIGHT / 2}
        text={value}
        align="center"
        width={WIDTH}
        height={HEIGHT}
        fontSize={HEIGHT}
        fill={color}
        fontStyle="bold"
      />
    </>
  );
};
