import { HStack, Text } from '@chakra-ui/react';
import { ReportVariable } from '@piccolohealth/echo-common';
import React from 'react';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';

interface Props {
  variable: ReportVariable;
}

export const MeasurementValue = (props: Props) => {
  const { variable } = props;

  const { compiledTemplate } = useCompiledTemplate(`{{withPlaceholder ${variable.id} '-'}}`);

  if (compiledTemplate !== '-') {
    return (
      <HStack minW="50px" flexShrink={0} justify="end">
        <Text>{compiledTemplate}</Text>
      </HStack>
    );
  }

  return <Text>{compiledTemplate}</Text>;
};
