import { P } from '@piccolohealth/util';
import { ScrollDirection } from '../../../hooks/useScroll';

export interface SynchronizationChild {
  id: string;
  duration: number;
  numberOfFrames: number;
  currentTime: number;
  frameRate: number;
  setFrameRate: (targetFrameRate: number) => void;
  setTime: (targetTime: number) => void;
  getTime: () => number;
}

export class Synchronizer {
  enabled: boolean;
  children: SynchronizationChild[];

  constructor() {
    this.enabled = false;
    this.children = [];
  }

  register(options: SynchronizationChild) {
    this.children.push(options);
  }

  deregister(id: string) {
    this.children = this.children.filter((c) => c.id !== id);
  }

  reset() {
    this.children = [];
  }

  setEnabled(value: boolean) {
    this.enabled = value;
  }

  onPlaybackProgress(id: string, currentTime: number, currentSpeed: number): void {
    if (!this.enabled) {
      return;
    }

    const child = this.getChild(id);
    if (!child) {
      return;
    }
    child.currentTime = currentTime;

    const master = this.getMaster();
    if (!master) {
      return;
    }

    const isMaster = master.id === id;
    if (!isMaster) {
      const childProgress = child.currentTime / child.duration;
      const masterProgress = master.currentTime / master.duration;

      const diff = Math.abs(masterProgress - childProgress);
      const progressDiff = Math.min(1.0 - diff, diff);

      const targetFrameRate = (child.duration / master.duration) * child.frameRate * currentSpeed;
      child.setFrameRate(targetFrameRate);

      if (progressDiff > 0.1) {
        const targetTime = masterProgress * child.duration;
        child.setTime(targetTime);
      }
    }
  }

  getChild(id: string) {
    return this.children.find((c) => c.id === id);
  }

  getMaster() {
    return P.maxBy(this.children, (c) => c.duration);
  }

  scroll(direction: ScrollDirection) {
    const master = this.getMaster();
    if (!master) {
      return;
    }

    this.children.forEach((c) => {
      const singleTick = c.duration / master.numberOfFrames;
      const singleTickWithDirection = direction === ScrollDirection.Down ? singleTick : -singleTick;
      const currentTime = c.getTime();

      const firstFrameTime = 0;
      const lastFrameTime = c.duration - singleTick;
      const targetCurrentTime = P.clamp(
        currentTime + singleTickWithDirection,
        firstFrameTime,
        lastFrameTime,
      );
      c.setTime(targetCurrentTime);
    });
  }
}
